<template>
  <div class="c-app flex-row align-items-center bg-blue-dark-200">
    <CContainer>
      <CRow class="justify-content-center">
        <CCol md="8">
          <CCardGroup>
            <CCard class="p-4">
              <CCardBody>
                <CForm>
                  <h4 class="mb-4">AUTENTICAÇÃO DE USUÁRIO</h4>
                  <CInput placeholder="Usuário" autocomplete="username" v-model="userAuth.access_key">
                    <template #prepend-content><CIcon name="cil-user"/></template>
                  </CInput>
                  <CInput placeholder="Senha" type="password" autocomplete="curent-password" v-model="userAuth.secret_key">
                    <template #prepend-content><CIcon name="cil-lock-locked"/></template>
                  </CInput>
                  <CRow>
                    <CCol col="6" class="text-left">
                      <CButton color="orange" class="px-4" @click="auth">ENTRAR</CButton>
                    </CCol>
                    <CCol col="6" class="text-right">
                      <CButton color="black" class="px-0">Esqueceu sua senha?</CButton>
                    </CCol>
                  </CRow>
                </CForm>
              </CCardBody>
            </CCard>

            <CCard class="p-5 bg-blue-dark-100">
              <CRow class="align-content-center align-items-center">
                  <CCol col="12" class="text-center">  
                    <img src="@/assets/img/logomarca/logo-horizontal-orange-white.png"  height="80" alt="logo" class="img-fluid"/>     
                  </CCol> 
              </CRow>
              <CRow class="pt-4"> 
                  <CCol col="12" class="text-center">  
                    <h3> DIGI-MANAGER </h3>     
                  </CCol>   
                  <CCol col="12" class="mt-2 text-center">  
                    <h6> Cliente: <strong>{{tenant.tenant_presentation}}</strong></h6>     
                  </CCol>         
              </CRow>  
            </CCard>
          </CCardGroup>
        </CCol>
      </CRow>
    </CContainer>
  </div>
</template>
<script>
import { signin } from "@/js/modules/auth.js";
export default {
  name: 'Login',
  computed: {
    tenant() {
      return this.$store.state.tenant;
    }
  },
  data() {
    return {
      userAuth: {},
    }
  },
  methods: {
    signin, 
    auth() {
      this.signin(this)
    }
  },
  created() {
    //this.userAuth.access_key = 'admin.fcmc'
    //this.userAuth.access_key = 'admin.encore'
    //this.userAuth.access_key = 'admin.digisystem'
    //this.userAuth.access_key = 'admin.digi-saude-tec'
    //this.userAuth.access_key = 'admin.unimed-litoral'
    //this.userAuth.access_key = 'admin.unimed-sudoeste'
    //this.userAuth.secret_key = 'Abc1234567*'
  }
}
</script>
